<template>
  <div class="scanCodeBox"
       @click="hidden">
    <transition name="el-fade-in-linear">
      <div class="content"
           v-show="show">
        <div style="font-weight: 700;">网红头条认证</div>
        <div class="code">
          <qrcode :value="value"
                  :size="size"
                  level="H"></qrcode>
          <div class="code overtime"
               v-if="overtime"
               @click.stop="refresh">
            <i class="el-icon-refresh"
               style="font-size:100px"></i>
            <p>二维码过期 点击刷新</p>
          </div>
        </div>
        <div class="explain">
          <div>请登录
            <a @click.stop="toindex"
               style="color: #2990F9;cursor:pointer;">网红头条APP</a>
            扫描二维码验证登录
          </div>
          <div @click.stop
               @dblclick="$emit('success', true)">“快手联营后台”</div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import qrcode from 'qrcode.vue'
import axios from "axios"
var pollingFn
export default {
  props: {
    formparm: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      value: 'false',
      size: 244,
      show: false,
      overtime: false
    };
  },
  components: {
    qrcode
  },
  mounted () {
  },
  methods: {
    refresh () {

      this.get_code()

      // 超时  5分钟
      setTimeout(() => {
        this.overtime = true
        this.value = 'false'
        clearInterval(pollingFn)
      }, 300000)
    },
    hidden () {
      this.$emit('success', false)
    },
    get_code () {
      axios({
        method: "post",
        url: '/mmapi/confirm-code/generate',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          phone: this.formparm.phone + '',
          plate_form: '1'
        }
      }).then(res => {
        const { info, data, status } = res.data
        console.log(data)
        if (status == 200) {
          this.overtime = false
          this.value = data.code
          this.polling()
        } else {
          this.$message({
            message: info,
            type: "warning",
          });
        }
      })
    },
    // 轮询请求结果
    polling () {
      pollingFn = setInterval(() => {
        axios({
          method: "post",
          url: '/mmapi/confirm-code/check',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            code: this.value
          }
        }).then(res => {
          const { data, status } = res.data
          if (status == 200) {
            if (data.confirmed) {
              this.$emit('success', true)
            }
          }
        })
      }, 1000)
    },
    toindex () {
      window.open("http://www.wanghongtoutiao.cn/")
    }
  },
  created () {
    setTimeout(() => {
      this.show = true
      this.refresh()
    }, 200)
    // setTimeout(() => this.$emit('success',true), 10000)
  },
  beforeDestroy () {
    this.show = false
    clearInterval(pollingFn)
  }
};
</script>
<style scoped lang="scss">
.scanCodeBox {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  font-size: 20px;
  .content {
    color: #fff;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 370px;
    top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .code {
      margin-top: 16px;
      width: 282px;
      height: 282px;
      background: #fff;
      padding: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .overtime {
      position: absolute;
      top: 0px;
      margin-top: 0px;
      background: rgba(0, 0, 0, 0.4);
      border: 19px solid #fff;
      flex-direction: column;
      font-size: 16px;
      cursor: pointer;
    }
    .explain {
      width: 370px;
      height: 70px;
      background: #000000;
      border-radius: 35px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
</style>